/* You can add global styles to this file, and also import other style files */
html,
body {
    height: 100%;
}

nlf-root,
main {
    display: block;
    height: 100%;
}

/** MODULES **/
.map-full-content {
    height: 100% !important;
    width: 100%;
    margin: 0;
}

.ngx-chart-wrapper {
    min-height: 400px !important;
}

/* Fix for bootstrap error disabled btns should show disabled icon */
button:disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
}

/* Highlite text etc **/
.highlight {
    background-color: #fffbce;
    font-weight: bold;
    padding: 1px;
}


/* FAB BUTTON */
.fab {
    width: 60px;

    height: 60px;
    font-size: 18px;
    padding-top: 18px;

    background-color: #ccc;
    border-radius: 50%;
    box-shadow: 0 6px 10px 0 #666;
    transition: all 0.1s ease-in-out;

    vertical-align: middle;
    color: white;
    text-align: center;
    /*border: 1px solid #cecece;*/
    /*line-height: 70px;
    font-size: 60px;
    */
    position: fixed;

    right: 50px;
    bottom: 50px;
    z-index: 999999;
}

.fab:hover {
    cursor: pointer;
    box-shadow: 0 6px 14px 0 #666;
    transform: scale(1.05);
}

.fab-icon {
    margin-top: 22px !important;
}

.ng-select.form-control {
    border: none;
    border-radius: 0 !important;
    padding: 0;
    width: 100%;
}

.ng-select.form-control .ng-select-container {
    min-height: 0px;
    height: 100%;
    width: 100%;
    border-radius: 0 !important;
    margin-bottom: -3px;
}

.nlf-sticky {
    background: white;
    margin-bottom: 0;
    width: 100%;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 998;
    overflow: hidden;
}

.nlf-sticky-toolbar {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #f7f7f7;
    border-bottom: 1px solid #868E96;
    width: 100%;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 998;
    overflow: hidden;
}

.nlf-toolbar {
    padding: 0.65rem 1.05rem;
    margin-bottom: 0;
    background-color: #f7f7f7;
    border-bottom: 1px solid #868E96;
    width: 100%;
    overflow: hidden;
}

.nlf-ors-toolbar {
    font-size: 1.2rem;
    padding: 0.3rem 0.3rem;
    margin-bottom: 0;
    /*background-color: #f7f7f7;*/
    border-bottom: 2px solid #868E96;
    width: 100%;
    overflow: hidden;
    margin-top: 0.7rem;
    margin-bottom: 0;
}

.breadcrumb {
    background: none !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    display: inline-flex !important;
}

.joyride-backdrop{
    z-index: 9999 !important;
}

.nlf-ors-block-bottom {
    margin-bottom: 1rem;
}

.nlf-ors-block-top {
    margin-top: 1rem;
}

.nlf-ors-block-blue {
    border-right: 3px solid #004465;
    padding-right: 2px;
}

.nlf-ors-block-light {
    border-right: 1px solid #5bbfcc;
}

.nlf-ors-block-dark {
    border-right: 1px solid #1d1d1b;
}

.nlf-ors-block-orange {
    border-right: 1px solid #f07f00;
}

.nlf-ors-block-purple {
    border-right: 1px solid #93648d;
}

.nlf-page-center {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 10px;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 999999;
}

/** TRIBUTE JS **/
.tribute-container {
    position: absolute;
    /** Only when triangle
  margin-top: 8px;*/
    top: 0px;
    left: 0;
    height: auto;
    /*overflow: auto; */
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 300px;
    max-width: 500px;
    display: block;
    z-index: 9990;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
}

/** Triangle up, don't work with overflow auto...
.tribute-container:before {
  content: "";
  position: absolute;
  left: 2px;
  top: -8px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #DDDDDD transparent;
  z-index: 9998;
}
.tribute-container:after {
  content: "";
  position: absolute;
  left: 4px;
  top: -6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #f7f7f7 transparent;
  z-index: 9999;
}
**/

.tribute-container ul {
    margin: 0;
    margin-top: 0px;
    padding: 0;
    list-style: none;
    background-color: #f7f7f7;
}

.tribute-container li {
    padding: 5px 5px;
    cursor: pointer;
}

.tribute-container li.highlight,
.tribute-container li:hover {
    background: #DDDDDD;
}

.tribute-container li span {
    font-weight: bold;
}

.tribute-container li.no-match {
    cursor: default;
}

.tribute-container .menu-highlighted {
    font-weight: bold;
}

.ngx-easy-table-small {
    font-size: 0.8rem !important;
}

.pointer {
    cursor: pointer;
}

.not-allowed:hover {
    cursor: not-allowed;
}

.drag-handle:hover {
    cursor: move;
}

/* NLF colors **/

.nlf-text-blue {
    color: #004465;
}

.nlf-text-light {
    color: #5bbfcc;
}

.nlf-text-dark {
    color: #1d1d1b;
}

.nlf-text-orange {
    color: #f07f00;
}

.nlf-text-purple {
    color: #93648d;
}

.nlf-bg-blue {
    background-color: #004465;
}

.nlf-bg-light {
    background-color: #5bbfcc;
}

.nlf-bg-dark {
    color: #1d1d1b;
}

.nlf-bg-orange {
    background-color: #f07f00;
}

.nlf-bg-purple {
    background-color: #93648d;
}

/* Startbootstrap */
.business-header {
    height: 50vh;
    min-height: 300px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.card {
    height: 100%;
}

/** Diffing **/
ins {
    color: black;
    background: #bbffbb;
}

del {
    color: black;
    background: #ffbbbb;
}

/** Fullscreen modals **/
.modal-full {
    top: 0;
    min-width: 100%;
    margin: 0;
}

.modal-full .modal-content {
    min-height: 100vh;
}

/** ngx-charts responsive **/
.chart-wrapper {
    display: flex;
    overflow: hidden;
    min-height: 16em;
}


/** CLEAR TIMELINE **/
.cbp_tmtimeline {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative
}

.cbp_tmtimeline:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3px;
    background: #eee;
    left: 20%;
    margin-left: -6px
}

.cbp_tmtimeline>li {
    position: relative
}

.cbp_tmtimeline>li:first-child .cbp_tmtime span.large {
    color: #444;
    font-size: 17px !important;
    font-weight: 700
}

.cbp_tmtimeline>li:first-child .cbp_tmicon {
    background: #fff;
    color: #666
}

.cbp_tmtimeline>li:nth-child(odd) .cbp_tmtime span:last-child {
    color: #444;
    font-size: 13px
}

.cbp_tmtimeline>li:nth-child(odd) .cbp_tmlabel {
    background: #f0f1f3
}

.cbp_tmtimeline>li:nth-child(odd) .cbp_tmlabel:after {
    border-right-color: #f0f1f3
}

.cbp_tmtimeline>li .empty span {
    color: #777
}

.cbp_tmtimeline>li .cbp_tmtime {
    display: block;
    width: 23%;
    padding-right: 70px;
    position: absolute
}

.cbp_tmtimeline>li .cbp_tmtime span {
    display: block;
    text-align: right
}

.cbp_tmtimeline>li .cbp_tmtime span:first-child {
    font-size: 15px;
    color: #3d4c5a;
    font-weight: 700
}

.cbp_tmtimeline>li .cbp_tmtime span:last-child {
    font-size: 14px;
    color: #444
}

.cbp_tmtimeline>li .cbp_tmlabel {
    margin: 0 0 15px 25%;
    background: #f0f1f3;
    padding: 0.4em 1.2em 1.2em 1.2em;
    position: relative;
    border-radius: 5px
}

.cbp_tmtimeline>li .cbp_tmlabel:after {
    right: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: #f0f1f3;
    border-width: 10px;
    top: 10px
}

.cbp_tmtimeline>li .cbp_tmlabel blockquote {
    font-size: 16px
}

.cbp_tmtimeline>li .cbp_tmlabel .map-checkin {
    border: 5px solid rgba(235, 235, 235, 0.2);
    -moz-box-shadow: 0px 0px 0px 1px #ebebeb;
    -webkit-box-shadow: 0px 0px 0px 1px #ebebeb;
    box-shadow: 0px 0px 0px 1px #ebebeb;
    background: #fff !important
}

.cbp_tmtimeline>li .cbp_tmlabel h2 {
    margin: 0px;
    padding: 0 0 10px 0;
    line-height: 26px;
    font-size: 16px;
    font-weight: normal
}

.cbp_tmtimeline>li .cbp_tmlabel h2 a {
    font-size: 15px
}

.cbp_tmtimeline>li .cbp_tmlabel h2 a:hover {
    text-decoration: none
}

.cbp_tmtimeline>li .cbp_tmlabel h2 span {
    font-size: 15px
}

.cbp_tmtimeline>li .cbp_tmlabel p {
    color: #444
}

.cbp_tmtimeline>li .cbp_tmicon {
    width: 40px;
    height: 40px;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 1.4em;
    line-height: 40px;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    color: #fff;
    background: #46a4da;
    border-radius: 50%;
    box-shadow: 0 0 0 5px #f5f5f6;
    text-align: center;
    left: 20%;
    top: 0;
    margin: 0 0 0 -25px
}

@media screen and (max-width: 992px) and (min-width: 768px) {
    .cbp_tmtimeline>li .cbp_tmtime {
        padding-right: 60px
    }
}

@media screen and (max-width: 65.375em) {
    .cbp_tmtimeline>li .cbp_tmtime span:last-child {
        font-size: 12px
    }
}

@media screen and (max-width: 47.2em) {
    .cbp_tmtimeline:before {
        display: none
    }

    .cbp_tmtimeline>li .cbp_tmtime {
        width: 100%;
        position: relative;
        padding: 0 0 20px 0
    }

    .cbp_tmtimeline>li .cbp_tmtime span {
        text-align: left
    }

    .cbp_tmtimeline>li .cbp_tmlabel {
        margin: 0 0 30px 0;
        padding: 1em;
        font-weight: 400;
        font-size: 95%
    }

    .cbp_tmtimeline>li .cbp_tmlabel:after {
        right: auto;
        left: 20px;
        border-right-color: transparent;
        border-bottom-color: #f5f5f6;
        top: -20px
    }

    .cbp_tmtimeline>li .cbp_tmicon {
        position: relative;
        float: right;
        left: auto;
        margin: -64px 5px 0 0px
    }

    .cbp_tmtimeline>li:nth-child(odd) .cbp_tmlabel:after {
        border-right-color: transparent;
        border-bottom-color: #f5f5f6
    }
}

.bg-green {
    background-color: #50d38a !important;
    color: #fff;
}

.bg-blush {
    background-color: #ff758e !important;
    color: #fff;
}

.bg-orange {
    background-color: #ffc323 !important;
    color: #fff;
}

.bg-info {
    background-color: #2CA8FF !important;
}



/*Avatar*/
.avatar {
    font-size: 1rem;
    position: relative;
    display: inline-block;
    width: 3.875rem;
    height: 3.875rem;
}

.avatar.avatar-xl {
    font-size: 1.70833rem;
    width: 5.125rem;
    height: 5.125rem;
}

.avatar.avatar-lg {
    font-size: 1.4rem;
    width: 4.5rem;
    height: 4.5rem;
}

.avatar.avatar-md {
    font-size: .95rem;
    width: 3.25rem;
    height: 3.25rem;
}

.avatar.avatar-sm {
    font-size: .9rem;
    width: 2.625rem;
    height: 2.625rem;
}

.avatar.avatar-xs {
    font-size: 1rem;
    width: 1.2rem;
    height: 1.2rem;
}

.avatar .avatar-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.avatar .avatar-text {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    text-transform: uppercase;
    overflow: hidden;
    background: #fff;
    font-weight: 600;
    color: #00acf0;
}

.avatar .avatar-text .initial-wrap {
    display: table;
    width: 100%;
    height: 100%;
}

.avatar .avatar-text .initial-wrap>span {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.avatar .avatar-text:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(178, 230, 250, 0.5);
}

.avatar .avatar-text.avatar-text-cyan,
.avatar .avatar-text.avatar-text-info {
    color: #1ebccd;
}

.avatar .avatar-text.avatar-text-cyan:before,
.avatar .avatar-text.avatar-text-info:before {
    background: rgba(187, 235, 240, 0.5);
}

.avatar .avatar-text.avatar-text-light {
    color: #6f7a7f;
}

.avatar .avatar-text.avatar-text-light:before {
    background: rgba(173, 179, 182, 0.5);
}

.avatar .avatar-text.avatar-text-dark {
    color: #fff;
}

.avatar .avatar-text.avatar-text-dark:before {
    background: #324148 !important;
}

.avatar .avatar-text.avatar-text-sky,
.avatar .avatar-text.avatar-text-primary {
    color: #00acf0;
}

.avatar .avatar-text.avatar-text-sky:before,
.avatar .avatar-text.avatar-text-primary:before {
    background: rgba(178, 230, 250, 0.5);
}

.avatar .avatar-text.avatar-text-green,
.avatar .avatar-text.avatar-text-success {
    color: #22af47;
}

.avatar .avatar-text.avatar-text-green:before,
.avatar .avatar-text.avatar-text-success:before {
    background: rgba(188, 231, 199, 0.5);
}

.avatar .avatar-text.avatar-text-red,
.avatar .avatar-text.avatar-text-danger {
    color: #f83f37;
}

.avatar .avatar-text.avatar-text-red:before,
.avatar .avatar-text.avatar-text-danger:before {
    background: rgba(253, 197, 195, 0.5);
}

.avatar .avatar-text.avatar-text-yellow,
.avatar .avatar-text.avatar-text-warning {
    color: #ffbf36;
}

.avatar .avatar-text.avatar-text-yellow:before,
.avatar .avatar-text.avatar-text-warning:before {
    background: rgba(255, 236, 194, 0.5);
}

.avatar .avatar-text.avatar-text-pink {
    color: #ed1b60;
}

.avatar .avatar-text.avatar-text-pink:before {
    background: rgba(250, 186, 207, 0.5);
}

.avatar .avatar-text.avatar-text-purple {
    color: #ab26aa;
}

.avatar .avatar-text.avatar-text-purple:before {
    background: rgba(230, 189, 229, 0.5);
}

.avatar .avatar-text.avatar-text-violet {
    color: #6640b2;
}

.avatar .avatar-text.avatar-text-violet:before {
    background: rgba(209, 197, 232, 0.5);
}

.avatar .avatar-text.avatar-text-indigo {
    color: #3a55b1;
}

.avatar .avatar-text.avatar-text-indigo:before {
    background: rgba(196, 204, 231, 0.5);
}

.avatar .avatar-text.avatar-text-blue {
    color: #0092ee;
}

.avatar .avatar-text.avatar-text-blue:before {
    background: rgba(178, 222, 250, 0.5);
}

.avatar .avatar-text.avatar-text-teal {
    color: #009b84;
}

.avatar .avatar-text.avatar-text-teal:before {
    background: rgba(178, 225, 218, 0.5);
}

.avatar .avatar-text.avatar-text-neon {
    color: #88c241;
}

.avatar .avatar-text.avatar-text-neon:before {
    background: rgba(219, 237, 198, 0.5);
}

.avatar .avatar-text.avatar-text-lime {
    color: #d0d962;
}

.avatar .avatar-text.avatar-text-lime:before {
    background: rgba(241, 244, 208, 0.5);
}

.avatar .avatar-text.avatar-text-sun {
    color: #fde335;
}

.avatar .avatar-text.avatar-text-sun:before {
    background: rgba(254, 247, 194, 0.5);
}

.avatar .avatar-text.avatar-text-orange {
    color: #ff9528;
}

.avatar .avatar-text.avatar-text-orange:before {
    background: rgba(255, 223, 190, 0.5);
}

.avatar .avatar-text.avatar-text-pumpkin {
    color: #ff6028;
}

.avatar .avatar-text.avatar-text-pumpkin:before {
    background: rgba(255, 207, 190, 0.5);
}

.avatar .avatar-text.avatar-text-brown {
    color: #7a5449;
}

.avatar .avatar-text.avatar-text-brown:before {
    background: rgba(215, 203, 200, 0.5);
}

.avatar .avatar-text.avatar-text-gold {
    color: #c1993f;
}

.avatar .avatar-text.avatar-text-gold:before {
    background: rgba(236, 224, 197, 0.5);
}

.avatar .avatar-text.avatar-text-grey,
.avatar .avatar-text.avatar-text-secondary {
    color: #9e9e9e;
}

.avatar .avatar-text.avatar-text-grey:before,
.avatar .avatar-text.avatar-text-secondary:before {
    background: rgba(226, 226, 226, 0.5);
}

.avatar .avatar-text.avatar-text-inv-cyan,
.avatar .avatar-text.avatar-text-inv-info {
    color: #fff;
}

.avatar .avatar-text.avatar-text-inv-cyan:before,
.avatar .avatar-text.avatar-text-inv-info:before {
    background-color: #1ebccd !important;
}

.avatar .avatar-text.avatar-text-inv-sky,
.avatar .avatar-text.avatar-text-inv-primary {
    color: #fff;
}

.avatar .avatar-text.avatar-text-inv-sky:before,
.avatar .avatar-text.avatar-text-inv-primary:before {
    background-color: #00acf0 !important;
}

.avatar .avatar-text.avatar-text-inv-green,
.avatar .avatar-text.avatar-text-inv-success {
    color: #fff;
}

.avatar .avatar-text.avatar-text-inv-green:before,
.avatar .avatar-text.avatar-text-inv-success:before {
    background-color: #22af47 !important;
}

.avatar .avatar-text.avatar-text-inv-red,
.avatar .avatar-text.avatar-text-inv-danger {
    color: #fff;
}

.avatar .avatar-text.avatar-text-inv-red:before,
.avatar .avatar-text.avatar-text-inv-danger:before {
    background-color: #f83f37 !important;
}

.avatar .avatar-text.avatar-text-inv-yellow,
.avatar .avatar-text.avatar-text-inv-warning {
    color: #fff;
}

.avatar .avatar-text.avatar-text-inv-yellow:before,
.avatar .avatar-text.avatar-text-inv-warning:before {
    background-color: #ffbf36 !important;
}

.avatar .avatar-text.avatar-text-inv-pink {
    color: #fff;
}

.avatar .avatar-text.avatar-text-inv-pink:before {
    background-color: #ed1b60 !important;
}

.avatar .avatar-text.avatar-text-inv-purple {
    color: #fff;
}

.avatar .avatar-text.avatar-text-inv-purple:before {
    background-color: #ab26aa !important;
}

.avatar .avatar-text.avatar-text-inv-violet {
    color: #fff;
}

.avatar .avatar-text.avatar-text-inv-violet:before {
    background-color: #6640b2 !important;
}

.avatar .avatar-text.avatar-text-inv-indigo {
    color: #fff;
}

.avatar .avatar-text.avatar-text-inv-indigo:before {
    background-color: #3a55b1 !important;
}

.avatar .avatar-text.avatar-text-inv-blue {
    color: #fff;
}

.avatar .avatar-text.avatar-text-inv-blue:before {
    background-color: #0092ee !important;
}

.avatar .avatar-text.avatar-text-inv-teal {
    color: #fff;
}

.avatar .avatar-text.avatar-text-inv-teal:before {
    background-color: #009b84 !important;
}

.avatar .avatar-text.avatar-text-inv-neon {
    color: #fff;
}

.avatar .avatar-text.avatar-text-inv-neon:before {
    background-color: #88c241 !important;
}

.avatar .avatar-text.avatar-text-inv-lime {
    color: #fff;
}

.avatar .avatar-text.avatar-text-inv-lime:before {
    background-color: #d0d962 !important;
}

.avatar .avatar-text.avatar-text-inv-sun {
    color: #fff;
}

.avatar .avatar-text.avatar-text-inv-sun:before {
    background-color: #fde335 !important;
}

.avatar .avatar-text.avatar-text-inv-orange {
    color: #fff;
}

.avatar .avatar-text.avatar-text-inv-orange:before {
    background-color: #ff9528 !important;
}

.avatar .avatar-text.avatar-text-inv-pumpkin {
    color: #fff;
}

.avatar .avatar-text.avatar-text-inv-pumpkin:before {
    background-color: #ff6028 !important;
}

.avatar .avatar-text.avatar-text-inv-brown {
    color: #fff;
}

.avatar .avatar-text.avatar-text-inv-brown:before {
    background-color: #7a5449 !important;
}

.avatar .avatar-text.avatar-text-inv-gold {
    color: #fff;
}

.avatar .avatar-text.avatar-text-inv-gold:before {
    background-color: #c1993f !important;
}

.avatar .avatar-text.avatar-text-inv-grey,
.avatar .avatar-text.avatar-text-inv-secondary {
    color: #fff;
}

.avatar .avatar-text.avatar-text-inv-grey:before,
.avatar .avatar-text.avatar-text-inv-secondary:before {
    background-color: #9e9e9e !important;
}

.avatar-group {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.avatar-group .avatar {
    font-size: .9rem;
    width: 2.625rem;
    height: 2.625rem;
}

.avatar-group .avatar .avatar-img,
.avatar-group .avatar .avatar-text {
    border: 2px solid #fff;
}

.avatar-group.avatar-group-overlapped .avatar {
    margin-right: -13px;
}

.avatar-group.avatar-group-overlapped .avatar:hover {
    z-index: 1;
}

.avatar-group.avatar-group-lg .avatar {
    font-size: .95rem;
    width: 3.25rem;
    height: 3.25rem;
}

.avatar-group.avatar-group-lg.avatar-group-overlapped .avatar {
    margin-right: -15px;
}

.avatar-group.avatar-group-sm .avatar {
    font-size: .6rem;
    width: 2rem;
    height: 2rem;
}

.avatar-group.avatar-group-sm.avatar-group-overlapped .avatar {
    margin-right: -10px;
}

.add-new-plus {
    height: 32px;
    text-align: center;
    width: 32px;
    display: block;
    line-height: 32px;
    color: #98a6ad;
    font-weight: 700;
    background-color: #e3eaef;
    border-radius: 50%
}

.btn-add-gray {
    border-radius: 50%;
    background: transparent;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
    border: 2px dashed #8f9eb5;
    color: #8f9eb5 !important;
    margin-left: 5px !important;
}

/** LEAFLET **/
.leaflet-pane.leaflet-shadow-pane {
    display: none;
}

.map{
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }

/** Bootstrap hacks **/
.card-header {
    padding: 0.75rem 1.25rem !important;;
    margin-bottom: 0!important;;
    color: inherit;
    background-color: rgba(0,0,0,.03) !important;
    border-bottom: 1px solid rgba(0,0,0,.125) !important;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}
